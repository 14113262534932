import React from 'react'
import { Link } from 'gatsby'
import Layout from '../layouts'
import OldMan from '../images/Outsourced-vp-sales.jpg'

const OutSource = () => (
	<Layout head={{ title: 'Outsourced / Fractional VP of Sales / CRO' }}>
		<div className="outsource">
			<div
				id="hero-section"
				className="bg-cover"
				style={{ background: `url("${OldMan}")` }}
			>
				<div className="container">
					<div className="slim-content">
						<h1 className="text-red">
							Outsourced / Fractional VP of Sales / CRO
						</h1>
						<p className="py-4">
							Your Outsourced VP of Sales can take on all of the work involved
							with running your sales team week-to-week. You need to run your
							business; let our Sales Consultants create a high-impact,
							disciplined sales culture to maximize sales at a fraction of the
							cost of a full-time VP of Sales.
						</p>
					</div>
				</div>
			</div>
			<div className="container py-5">
				<center className="mb-4">
					<h2 className="text-red">
						Can a Fractional VP of Sales help your business?
					</h2>
					<h3 className="text-red my-4">
						Take the free 10 Question Assessment to find out:
					</h3>
					<a
						className="btn btn-primary red-btn btn-lg"
						href="https://www.salesxceleration.com/take-sales-agility-assessment/?gf_advisor_slug=joe-dauskurdas"
						target="_blank"
						rel="noopener noreferrer"
					>
						FREE ASSESSMENT
					</a>
				</center>
				<p>
					Sales Leadership is a critical discipline that only comes from
					experience and training. We utilize the Sales Xceleration Certified
					Sales Leadership (CSL) Platform that was developed with input from 80+
					Sales Executives with an average of 20+ years experience.
				</p>
				<p>
					ProGrowth Advisors Founder Joe Dauskurdas is a Certified Sales Leader
					with 28+ year of sales and marketing leadership specializing in
					creating high impact, rapid growth sales strategies, processes and
					winning teams. After completing an in-depth Sales Growth Assessment,
					we develop a comprehensive scope of work and deliverables that we
					execute while building and leading your sales team.
				</p>
				<p>
					If you are struggling to grow or looking for help scaling your
					business, look no more. Contact ProGrowth Advisors today at{' '}
					<a
						href="tel:512-696-1333"
						target="_blank"
						rel="noopener noreferrer"
						title="Contact"
					>
						512-696-1333
					</a>{' '}
					or{' '}
					<a
						href="mailto:Joe@ProGrowthAdvisors.com"
						target="_blank"
						rel="noopener noreferrer"
						title="mail"
					>
						Joe@ProGrowthAdvisors.com
					</a>
					.
				</p>

				<center className="my-4">
					<Link to="/about" className="btn btn-primary red-btn">
						Meet The Founder
					</Link>
				</center>
			</div>
		</div>
	</Layout>
)
export default OutSource
